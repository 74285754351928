import React, { useState, useEffect } from "react";
import axios from 'axios'
import Modal from 'react-modal';
import HeaderBlock from './header'
import logo from '../assets/nn23.png'
import winnerTrophy from '../assets/trophy.png'
import closeButton from '../assets/close.png'

const customStyles = {
    content: {
        width: '300px', // Set width to 300px
        height: 'auto', // Set height to auto
        maxHeight: '368px', // Set maximum height to 300px
        margin: 'auto', // Center align horizontally
        top: '50px', // Position from the top
        left: '50%', // Position from the left
        padding: '10px 12px 0 5px', // Add padding
        transform: 'translate(0%, 0)',  // Center align vertically and horizontally
        border: '1px solid #333333', // Add border
        backgroundColor: 'black',
        color: 'white',
        overflow: 'hidden',
        inset: '50px 0 40px 0'
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
    }
  };

function formatTime(totalSeconds) {
    if (parseInt(totalSeconds) !== "" && !isNaN(parseInt(totalSeconds))) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        let formattedTime = '';
        if (hours > 0) {
            formattedTime = `${hours}:${formattedMinutes}:${formattedSeconds}`;
        } else {
            formattedTime = `${formattedMinutes}:${formattedSeconds}`;
        }

        if (formattedTime !== "aN:aN:aN" || formattedTime !== "00:00") {
            return formattedTime;
        } else {
            return "NO SCAN"
        }
    } else {
        return "NO SCAN"
    }
    
}

const ResultsTable = () => {
    const winArray = []
    const winArrayClass = []
    const [raceData, setData] = useState([]);
    const [startTime, startData] = useState([]);
    const [clickedRow, setClickedRow] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [rank, setRank]  = useState(null);
    const [categoryType, setCategoryType] = useState('PB');



    // function openModal() {
    //   setIsOpen(true);
    // }
  
    // function afterOpenModal() {
    //   // references are now sync'd and can be accessed.
    //   subtitle.style.color = '#f00';
    // }
  
    // function closeModal() {
    //   setIsOpen(false);
    // }

    useEffect(() => {
        fetchData(); 
        const interval = setInterval(() => {
            fetchData(); 
          }, 10000); 

          return () => clearInterval(interval); 
      }, []);

    const isWinningImage = (passedWinner, passedTime) => {
        if (winArray.includes(passedWinner) || passedTime === "DNF"){
        } else {
            winArray.push(passedWinner)
            return <img src={winnerTrophy} alt="Winner" height="10px"/>;
        }
    }


    const isWinningClass = (passedWinner, passedTime) => {
        if (winArrayClass.includes(passedWinner) || passedTime === "DNF"){
            return "norm"
        } else {
            winArrayClass.push(passedWinner)
            return 'winner'
        }
    }

    const DNS = (value) => {
        if (value === null || value === undefined || value === "00:00" || value === "aN:aN:aN"){
            return "NO SCAN"
        } else {
            return value
        }
    }

    const isDNF = (passedTime) => {
        if (passedTime === "DNF"){
            return "dnf"
        } else {
            return 'finisher'
        }
    }

    const checkForNaN = (value) => {
        if (value === "aN:aN:aN"){
            return "DNF"
        } else {
            return value
        }
    }

    const handleRowClick = (index, data) => {
        setClickedRow(index === clickedRow ? null : index);
        setRank(index+1)
        setRowData(data, index);

    };

    const validPosition = (value, position) => {
        if (value === "NO SCAN"){   
            return "/"
        } else {
            return position
        }
    }


    const fetchData = async () => {
        try {
            const response = await axios.get('/api')
            // const response = await axios.get('http://localhost:3000/api')
            const data = response.data.runners.data
            const raceData = data
            setData(raceData);
            // if (response.data.start[0] !== undefined) {
            //     startData(response.data.start[0].startTimeValue);
            // } else {
            //     startData(0)
            // }
            
        } catch (err) {
            // console.log(err.message);
        }
      }; 




    
    return (
        <div className="user">
        <HeaderBlock header="The Wild West Ultra 2025" logo={logo}  raceTime={startTime}  />
        {/* <div className="instructions">Tap on a rider for more info</div> */}
        <div className="tenBlock">
        <table id="runners">
            <tbody>
              
                {categoryType === 'TWT' || categoryType === 'BB' || categoryType === 'PB'   ? (
                <>
                  <tr className="headerTable">
                    <th width="15px" className="alignCenter">#</th>
                    <th className="runnerCol">Runner</th>
                    <th>Team</th>
                    <th width="40px" className="alignCenter">Cat</th>
                    <th width="20px" className="alignCenter">Band</th>
                    <th width="20px" className="alignCenter">Time</th>
                </tr> 
                {raceData
                .filter((data) => data.events === categoryType)
                .sort((a, b) => {
                    // Replace 'valueToSortBy' with the specific value you want to sort by
                    return a.totalTime.localeCompare(b.totalTime);
                })
                .map((key, index)=> (

                    <tr key={index+1} className={isDNF(key.totalTime)} onClick={() => handleRowClick(index, key)} >
                        <td>{index+1}</td>
                        <td>{key.firstName + " "}{key.lastName+ " "}  { isWinningImage(key.ageGroup, key.totalTime) } </td>
                        <td>{key.runClub}</td>
                        <td className={"alignCenter " + isWinningClass(key.ageGroup, key.totalTime)}>{ key.ageGroup } </td>
                        <td className="alignCenter">{key.raceNumber}</td>
                        <td className="alignCenter">{checkForNaN(key.totalTime)}</td> 
                    </tr>
                ))}
                </>
                ) : null} 

                {categoryType === 'WOD'  ? (
                <>
                  <tr className="headerTableClimbs">
                    <th width="15px" className="alignCenter">#</th>
                    <th className="runnerCol">Runner</th>
                    <th>Team</th>
                    <th className="alignCenter">Cat</th>
                    <th className="alignCenter">Race</th>
                    {/* <th className="alignCenter">Bib</th> */}
                    {/* <th width="70px" className="alignCenter">WOD Time</th> */}
                </tr> 
                {raceData
                .filter((data) => data.totalTime !== "DNF")
                 .sort((a, b) => {
                    // Replace 'valueToSortBy' with the specific value you want to sort by
                    //return a.wodTime.localeCompare(b.wodTime);
                    if (a.wodTime === '' && b.wodTime === '') {
                        return 0; // Both values are equal
                    } else if (a.wodTime === '') {
                        return 1; // '00:00:00' should appear last
                    } else if (b.wodTime === '') {
                        return -1; // '00:00:00' should appear last
                    }
                    
                    // Compare time strings directly
                    return a.wodTime.localeCompare(b.wodTime);
                })
                .map((key, index)=> (

                    <tr key={index+1} className={isDNF(key.totalTime)} onClick={() => handleRowClick(index, key)} >
                        <td>{index+1}</td>
                        <td>{key.firstName + " "}{key.lastName+ " "}  </td>
                        <td>{key.runClub}</td>
                        <td className="alignCenter">{key.ageGroup}</td>
                        {/* <td className="alignCenter">{key.events}</td> */}
                        <td className="alignCenter">{key.raceNumber}</td>
                        {/* <td className="alignCenter">{formatTime(key.wodTime)}</td>  */}
                    </tr>
                ))}
                </>
                ) : null} 

                {categoryType === 'CarnIngli'  ? (
                <>
                  <tr className="headerTableClimbs">
                    <th width="15px" className="alignCenter">#</th>
                    <th className="runnerCol">Runner</th>
                    <th>Team</th>
                    <th  className="alignCenter">Cat</th>
                    <th className="alignCenter">Race</th>
                    <th className="alignCenter">Band</th>
                    <th width="70px" className="alignCenter">Climb Time</th>
                </tr> 
                {raceData
                .filter((data) => data.events === "PB" && data.totalTime !== "DNF")
                 .sort((a, b) => {
                    //return a.carnIngliTime.localeCompare(b.carnIngliTime);

                    if (a.carnIngliTime === '' && b.carnIngliTime === '') {
                        return 0; // Both values are equal
                    } else if (a.carnIngliTime === '') {
                        return 1; // '00:00:00' should appear last
                    } else if (b.carnIngliTime === '') {
                        return -1; // '00:00:00' should appear last
                    }
                    
                    // Compare time strings directly
                    return a.carnIngliTime.localeCompare(b.carnIngliTime);

                })
                .map((key, index)=> (

                    <tr key={index+1} className={isDNF(key.totalTime)} onClick={() => handleRowClick(index, key)} >
                        <td>{index+1}</td>
                        <td>{key.firstName + " "}{key.lastName+ " "}  </td>
                        <td>{key.runClub}</td>
                        <td className="alignCenter">{key.ageGroup}</td>
                        <td className="alignCenter">{key.events}</td>
                        <td className="alignCenter">{key.raceNumber}</td>
                        <td className="alignCenter">{formatTime(key.carnIngliTime)}</td> 
                    </tr>
                ))}
                </>
                ) : null} 

        </tbody>

                    {/* Modal */}
                    <Modal
                isOpen={clickedRow !== null}
                onRequestClose={() => {
                    setClickedRow(null);
                    setRowData(null);
                }}
                contentLabel="Example Modal"
                style={customStyles}
            >
                 <button className="closeButton" onClick={() => setClickedRow(null)}><img src={closeButton} alt="Winner" width="20px" height="20px"/></button>
                {/* Render modal content with rowData */}
                {rowData && (
                    <div className="modalContent">
                        <h2>{rowData.firstName} {rowData.lastName}</h2>

                        <table id="runners">
                            <thead>
                                <tr>
                                    <th>Stage</th>
                                    <th>Time</th>
                                    <th>Pos</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                <tr>
                                    <td>Freshwater West</td>
                                    <td>{DNS(rowData.checkPointOne)}</td>
                                    <td>{validPosition(DNS(rowData.checkPointOne),DNS(rowData.checkPointOnePosition))}</td>
                                </tr>
                                <tr>
                                    <td>Angle (Feed station)</td>
                                    <td>{DNS(rowData.checkPointTwo)}</td>
                                    <td>{validPosition(DNS(rowData.checkPointTwo),DNS(rowData.checkPointTwoPosition))}</td>
                                </tr>
                                <tr>
                                    <td>Power Station</td>
                                    <td>{DNS(rowData.checkPointThree)}</td>
                                    <td>{validPosition(DNS(rowData.checkPointThree),DNS(rowData.checkPointThreePosition))}</td>
                                </tr>
                                <tr>
                                    <td>Monkton</td>
                                    <td>{DNS(rowData.checkPointFour)}</td>
                                    <td>{validPosition(DNS(rowData.checkPointFour),DNS(rowData.checkPointFourPosition))}</td>
                                </tr>
                                <tr>
                                    <td>Stackpole Road Crossing</td>
                                    <td>{DNS(rowData.checkPointFive)}</td>
                                    <td>{validPosition(DNS(rowData.checkPointFive),DNS(rowData.checkPointFivePosition))}</td>
                                </tr>
                                <tr>
                                    <td>Broadhven South (Bridge)</td>
                                    <td>{DNS(rowData.checkPointSix)}</td>
                                    <td>{validPosition(DNS(rowData.checkPointSix),DNS(rowData.checkPointSixPosition))}</td>
                                </tr>
                              
                            </tbody>

                            <tbody>
                                <tr className="totalTime">
                                    <td>Overall</td>
                                    <td>{checkForNaN(rowData.totalTime)}</td>
                                    <td>{rank} / {rowData.totalRunners}</td>
                                </tr>

                            </tbody>
                    </table>

                    
                    </div>
                )}
            </Modal>
        </table>
        </div>
       <div className="footerHolder">
                <div class="footerText">
                    Race timing by <a href="https://www.realtimeracing.co.uk/" target="_blank" rel="noreferrer" className="footer">Realtime Racing</a>
                </div> 
        </div>
        </div>
    );
}

export default ResultsTable;