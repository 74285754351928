import { useState, useEffect } from "react";
import moment from 'moment';

function HeaderBlock(props) {
    const [raceTime, startData] = useState([]);

    const raceTimeCalc = (startTime) => {
        if (startTime == 0) {
            return ("This race will start at 5:30pm on December 9th")

        } else {
        const currentDate = moment(); 
        const duration = moment.duration(currentDate.diff(moment(startTime, 'YYYY-MM-DD HH:mm:ss')))
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        let formattedTime = "";

        if (duration.asHours() < 3.5 && duration.asHours() > 0 ) {
            if (hours > 0) {
                formattedTime += `${hours}:`;
            }
            if (minutes > 0 || hours > 0) {
                formattedTime += `${minutes.toString().padStart(2, '0')}:`;
            }

            if (seconds >= 0 || (hours === 0 && minutes === 0)) {
                formattedTime += `${seconds.toString().padStart(2, '0')}`;
            }
            return ("Race time: " + formattedTime)
        }

        if (duration.asHours() > 3.5) {
            return ("The race has now finished")
        }

        if (duration.asHours() < 0) {
            return ("This race will start at 5:30pm on December 9th")
        }

       
        return;
    }
        
    }

    // useEffect(() => {
    //     startData(raceTimeCalc(props.raceTime)); 
    //     const interval = setInterval(() => {
    //         startData(raceTimeCalc(props.raceTime)); 
    //       }, 1000); 

    //       return () => clearInterval(interval); 
    //   }, [props.raceTime]);


    return (
        <header>
            <img src={props.logo} alt={props.header} className="logo"/>
            <h1>{props.header}</h1>
            <p>Tap on a runner to see more info</p>
        </header>
    );
}

export default HeaderBlock;