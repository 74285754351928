import { useState } from "react";
import {Link} from 'react-router-dom'
import useUser from "../components/useUser"


function EditingRow(props) {
    const [isTakenVisible, setTakenVisible] = useState(false);
    const [genericVisible, setGenericVisible] = useState(false);

function handleResult(passedResult){
    if (passedResult.result === "Success"){
        props.parentFunction()
        props.passChildData('');
        setGenericVisible(false)
        setTakenVisible(false)
    } else if (passedResult.result === "Taken") {
        setGenericVisible(false)
        setTakenVisible(true)
    } else {
        setTakenVisible(false)
        setGenericVisible(true)
    }
}

function cancelEdit() {
    props.passChildData('');
}

async function submitForm(event) {

    event.preventDefault();
    const data = new FormData(event.target);
    const dataBlob = []
    dataBlob.push(data.get('raceNumber'))
    dataBlob.push(data.get('ageGroup'))
    dataBlob.push(data.get('firstname'))
    dataBlob.push(data.get('lastname'))
    dataBlob.push(data.get('event'))
    dataBlob.push(data.get('club'))
    dataBlob.push(data.get('recordId'))
    dataBlob.push(data.get('bibNumber'))


    console.log(dataBlob)
    const token = await user.getIdToken()
    // const token = await user.getIdToken()
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'authtoken': token },
        // headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({dataBlob})
    };
    
    fetch('/api', requestOptions)
        .then(response => response.json())
        .then(response => handleResult(response))
        .catch(error => {
            //setformError(error)
            //this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
            
        });
}

    const {user, isLoading} = useUser() 

    return(
        <>
        {user ?
        <tr>
                    <td colSpan="6"  className="editBG" >
                        <div className="editArea" >
                            <form onSubmit={submitForm}>
                                {isTakenVisible && <div className="error">This number is already assigned.</div> }
                                {genericVisible && <div className="error">There was an error submitting this entry.</div> }
                                <div className="leftcolHolder">
                                    <div className={"raceNumberHolder " + props.rowData.mobileNumber}>
                                        <div className="fieldLabel">Wristband number: </div>
                                        <div className="fieldInput">
                                            <input type="number" name="raceNumber" className="raceNumber" defaultValue={props.rowData.raceNumber} id="raceNumber"/>
                                        </div>
                                    </div>
                                    {/* <div className={"raceNumberHolder " + props.rowData.mobileNumber}>
                                        <div className="fieldLabel">Bib number: </div>
                                        <div className="fieldInput">
                                            <input type="number" name="bibNumber" className="bibNumber" defaultValue={props.rowData.bibNumber} id="bibNumber"/>
                                        </div>
                                    </div> */}

<div className="fieldSet">
                                    <div className="fieldLabel">Age cat:</div>
                                    <div className="fieldInput">
                                        <select className="selectedAge" name="ageGroup" defaultValue={props.rowData.ageGroup }>
                                            <option>F18</option>
                                            <option>F40</option>
                                            <option>F50</option>
                                            <option>F60</option>
                                            <option>M18</option>
                                            <option>M40</option>
                                            <option>M50</option>
                                            <option>M60</option>
                                        </select>
                                    </div>
                                </div> 
                                   
                                </div>
                        
                               <div className="runnersNameHolder">
                                <div className="fieldSet">
                                    <div className="fieldLabel">First name:</div>
                                    <div className="fieldInput">
                                        <input type="text" name="firstname" className="firstname" defaultValue={props.rowData.firstName}/>
                                    </div>
                                </div>
                                <div className="fieldSet">
                                    <div className="fieldLabel">Last name:</div>
                                    <div className="fieldInput">
                                        <input type="text" name="lastname" className="surname" defaultValue={props.rowData.lastName}/>
                                    </div>
                                </div>
                            
                   
                                    <div className="fieldSet">
                                    <div className="fieldLabel">Event:</div>
                                    <div className="fieldInput">
                                        <select className="selectedEvent" name="event" defaultValue={props.rowData.event}>
                                            {/* <option value="TWT">TWT</option>
                                            <option value="BB">Bach</option> */}
                                            <option value="PB">WWU</option>
                                        </select>
                                    </div>
                           
                               
                                </div>
                              
                            </div>
                            <div className="fullWidthField">
                                    <div className="fieldSet">
                                    <div className="fieldLabel">Team:</div>
                                    <div className="fieldInput">
                                        <input type="text" name="club" className="surname" defaultValue={props.rowData.runClub}/>
                                    </div>
                                    </div>
                               
                                </div>

                                {/* <div className="fullWidthField">
                                    <div className="fieldSet">
                                    <div className="fieldLabel">Override time: (Set at 00:00:01 for DNF)</div>
                                    <div className="fieldInput">
                                        <input type="text" name="overrideTime" className="surname" defaultValue={props.rowData.overrideTime}/>
                                    </div>
                                    </div>
                               
                                </div> */}
                                <div className="leftcolHolder">
                                <div className="cancelBtn"><a onClick={cancelEdit} className="btn cancel" >Cancel</a></div>
                                </div>
                                <div className="runnersNameHolder">
                                <input type="hidden" name="recordId" value={props.rowData.runnerId} />
                                <div className="submitBtn"><button className="btn submit">Submit</button></div>
                            </div>

                            </form>
                        </div>
                    </td>
                </tr>
                 : <Link className="loginLink"to="/login">Login</Link>
                }
                </>
    )   
}

export default EditingRow;



